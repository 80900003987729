import React from 'react';

const Card = ({ title, date,admin, imageUrl, onSelect }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '5px', border: '1px solid #ccc', marginBottom: '1px' }}>
            <div style={{ flex: 2 }}>
                <img src={imageUrl} alt={title} style={{ width: '80%', marginRight: '5px' }} />
            </div>
            <div style={{ flex: 3 }}>
                <h4>{title}</h4>
                <p>{date}</p>
                <p>User : {admin}</p>
                <button onClick={onSelect} className="bp5-button bp5-intent-primary" style={{ marginBottom: '5px' }}  >Open Project</button>
            </div>
        </div>
    );
};

export default Card;
