import React from 'react';
import ReactDOM from 'react-dom/client';

import { createStore } from 'polotno/model/store';
import { unstable_setAnimationsEnabled } from 'polotno/config';
import { Auth0Provider } from '@auth0/auth0-react';
import { createProject, ProjectContext } from './project';
import { SubscriptionProvider } from './subscription-context';
import { setTranslations } from 'polotno/config';



import fr from './translations/fr.json';

import './index.css';
import App from './App';
import './logger';
import { ErrorBoundary } from 'react-error-boundary';

// if (window.location.host !== 'studio.polotno.com') {
//   console.log(
//     `%cWelcome to Polotno Studio! Thanks for your interest in the project!
// This repository has many customizations from the default version Polotno SDK.
// I don't recommend to use it as starting point.
// Instead, you can start from any official demos, e.g.: https://polotno.com/docs/demo-full-editor
// or direct sandbox: https://codesandbox.io/s/github/polotno-project/polotno-site/tree/source/examples/polotno-demo?from-embed.
// But feel free to use this repository as a reference for your own project and to learn how to use Polotno SDK.`,
//     'background: rgba(54, 213, 67, 1); color: white; padding: 5px;'
//   );
// }

unstable_setAnimationsEnabled(true);

//const store = createStore({ key: 'nFA5H9elEytDyPyvKL7T' });
const store = createStore({ key: 'LJ1ZXJcrW619wiP9uwDz' });
window.store = store;
store.addPage();



const checkPageNumbers = () => {
  // check every page
  store.pages.forEach((page, index) => {

    let pageNumber = page.children.find(
        (el) => el.custom?.name === 'page-number'
    );
    const text = `Page ${index + 1}`;
    if (pageNumber) {
      // if such element is already here, let's update it
      // in the case if a user reoderer pages
      pageNumber.set({ text });
    } else {
      // or just create a new one
      page.addElement({
        type: 'text',
        custom: { name: 'page-number' },
        text,
        width: store.width,
        align: 'center',
        fontSize: 20,
        x: 0,
        y: store.height + 30,
        selectable: false,
        alwaysOnTop: true,
      });
    }
  });
};

checkPageNumbers();
store.on('change', checkPageNumbers);

const project = createProject({ store });
window.project = project;

const root = ReactDOM.createRoot(document.getElementById('root'));

//const AUTH_DOMAIN = 'polotno-studio.eu.auth0.com';
const AUTH_DOMAIN = 'google.com';
const PRODUCTION_ID = process.env.REACT_APP_AUTH0_ID;
const LOCAL_ID = process.env.REACT_APP_AUTH0_ID;

const isLocalhost = true;
const ID = isLocalhost ? LOCAL_ID : PRODUCTION_ID;
const REDIRECT = isLocalhost
  ? 'http://localhost:3000'
  : 'https://studio.polotno.com';

function Fallback({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <div style={{ textAlign: 'center', paddingTop: '40px' }}>
        <p>Something went wrong in the app.</p>
        <p>Try to reload the page.</p>
        <p>If it does not work, clear cache and reload.</p>
        <button
          onClick={async () => {
            await project.clear();
            window.location.reload();
          }}
        >
          Clear cache and reload
        </button>
      </div>
    </div>
  );
}

root.render(
  <ErrorBoundary
    FallbackComponent={Fallback}
    onReset={(details) => {
      // Reset the state of your app so the error doesn't happen again
    }}
    onError={(e) => {
      if (window.Sentry) {
        window.Sentry.captureException(e);
      }
    }}
  >
    <ProjectContext.Provider value={project}>
      <Auth0Provider domain={AUTH_DOMAIN} clientId={ID} redirectUri={REDIRECT}>
        <SubscriptionProvider>
          <App store={store} />
        </SubscriptionProvider>
      </Auth0Provider>
    </ProjectContext.Provider>
  </ErrorBoundary>
);
