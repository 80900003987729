import React from 'react';
import { observer } from 'mobx-react-lite';
import {
    Button,
    Dialog,
    Classes,
    Position,
    Menu,
    MenuItem,
    MenuDivider,
    Popover,
} from '@blueprintjs/core';
import { downloadFile } from 'polotno/utils/download';

// Tambahkan fungsi saveFileToServer untuk menyimpan file ke server
async function saveFileToServer(dataURL, filename) {
    try {
        const blob = dataURItoBlob(dataURL);
        const formData = new FormData();
        formData.append('file', blob, filename);

        const response = await fetch('http://localhost:80/api/simpan.php', {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            throw new Error('Gagal menyimpan file ke server.');
        }

        return await response.text();
    } catch (error) {
        throw new Error('Gagal menyimpan file ke server.');
    }
}
async function saveTemplateToServer(dataURL, filename) {
    try {
        const blob = dataURItoBlob(dataURL);
        const formData = new FormData();
        formData.append('file', blob, filename);

        const response = await fetch('http://localhost:80/api/simpan_template.php', {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            throw new Error('Gagal menyimpan file ke server.');
        }

        return await response.text();
    } catch (error) {
        throw new Error('Gagal menyimpan file ke server.');
    }
}


// Fungsi bantu untuk mengonversi data URI menjadi blob
function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
}

export const FileMenu = observer(({ store, project }) => {
    const inputRef = React.useRef();

    const [faqOpened, toggleFaq] = React.useState(false);
    return (
        <>
            <Popover
                content={
                    <Menu>
                        <MenuItem
                            icon="plus"
                            text="New"
                            onClick={() => {
                                const ids = store.pages
                                    .map((page) => page.children.map((child) => child.id))
                                    .flat();
                                const hasObjects = ids?.length;
                                if (hasObjects) {
                                    if (!window.confirm('Remove all content for a new design?')) {
                                        return;
                                    }
                                }
                                const pagesIds = store.pages.map((p) => p.id);
                                store.deletePages(pagesIds);
                                store.addPage();
                                project.id = '';
                                project.save();
                            }}
                        />
                        <MenuDivider />
                        <MenuItem
                            icon="folder-open"
                            text="Open"
                            onClick={() => {
                                document.querySelector('#load-project').click();
                            }}
                        />
                        <MenuItem
                            icon="export"
                            text="Export"
                            onClick={() => {
                                const json = store.toJSON();

                                const url =
                                    'data:text/json;base64,' +
                                    window.btoa(
                                        unescape(encodeURIComponent(JSON.stringify(json)))
                                    );

                                downloadFile(url, 'paperwork.json');
                            }}
                        />


                        {/* Tambahkan menu "Save" yang akan menyimpan proyek ke server */}
                        <MenuItem
                            icon="floppy-disk"
                            text="Save "
                            onClick={() => {
                                const json = store.toJSON();

                                const url =
                                    'data:text/json;base64,' +
                                    window.btoa(
                                        unescape(encodeURIComponent(JSON.stringify(json)))
                                    );

                                // Menggunakan fungsi saveFileToServer untuk menyimpan proyek ke server
                                saveFileToServer(url, 'paperwork.json')
                                    .then(() => {
                                        alert('Proyek berhasil disimpan ke server.');
                                    })
                                    .catch((error) => {
                                        console.error('Gagal menyimpan proyek ke server:', error);
                                        alert('Gagal menyimpan proyek ke server.');
                                    });
                            }}
                        />


                        <MenuItem
                            icon="floppy-disk"
                            text="Save Template "
                            onClick={() => {
                                const json = store.toJSON();

                                const url =
                                    'data:text/json;base64,' +
                                    window.btoa(
                                        unescape(encodeURIComponent(JSON.stringify(json)))
                                    );

                                // Menggunakan fungsi saveFileToServer untuk menyimpan proyek ke server
                                saveTemplateToServer(url, 'template.json')
                                    .then(() => {
                                        alert('Proyek berhasil disimpan ke server.');
                                    })
                                    .catch((error) => {
                                        console.error('Gagal menyimpan proyek ke server:', error);
                                        alert('Gagal menyimpan proyek ke server.');
                                    });
                            }}
                        />


                        <MenuDivider />
                        <MenuItem text="Language" icon="translate">
                            <MenuItem
                                text="English"
                                active={project.language.startsWith('en')}
                                onClick={() => {
                                    project.setLanguage('en');
                                }}
                            />
                            <MenuItem
                                text="Indonesian"
                                active={project.language.startsWith('id')}
                                onClick={() => {
                                    project.setLanguage('id');
                                }}
                            />
                        </MenuItem>
                    </Menu>
                }
                position={Position.BOTTOM_RIGHT}
            >
                <Button minimal text="File" />
            </Popover>
            <input
                type="file"
                id="load-project"
                accept=".json,.polotno"
                ref={inputRef}
                style={{ width: '180px', display: 'none' }}
                onChange={(e) => {
                    var input = e.target;

                    if (!input.files.length) {
                        return;
                    }

                    var reader = new FileReader();
                    reader.onloadend = function () {
                        var text = reader.result;
                        let json;
                        try {
                            json = JSON.parse(text);
                        } catch (e) {
                            alert('Can not load the project.');
                        }

                        if (json) {
                            store.loadJSON(json);
                            input.value = '';
                        }
                    };
                    reader.onerror = function () {
                        alert('Can not load the project.');
                    };
                    reader.readAsText(input.files[0]);
                }}
            />
            <Dialog
                icon="info-sign"
                onClose={() => toggleFaq(false)}
                title="About Polotno Studio"
                isOpen={faqOpened}
                style={{
                    width: '80%',
                    maxWidth: '700px',
                }}
            >
                <div className={Classes.DIALOG_BODY}>
                    <h2>What is Polotno Studio?</h2>
                    <p>
                        <strong>Polotno Studio</strong> - is a web application to create
                        graphical designs. You can mix image, text and illustrations to make
                        social media posts, youtube previews, podcast covers, business cards
                        and presentations.
                    </p>
                    <h2>Is it Open Source?</h2>
                    <p>
                        Partially. The source code is available in{' '}
                        <a href="https://github.com/lavrton/polotno-studio" target="_blank">
                            GitHub repository
                        </a>
                        . The repository doesn't have full source.{' '}
                        <strong>Polotno Studio</strong> is powered by{' '}
                        <a href="https://polotno.dev/" target="_blank">
                            Polotno SDK project
                        </a>
                        . All core "canvas editor" functionality are implemented by{' '}
                        <strong>polotno</strong> npm package (which is not open source at
                        the time of writing this text).
                    </p>
                    <p>
                        Polotno Studio is build on top of Polotno SDK to provide a
                        desktop-app-like experience.
                    </p>
                    <h2>Who is making Polotno Studio?</h2>
                    <p>
                        My name is Anton Lavrenov{' '}
                        <a href="https://twitter.com/lavrton" target="_blank">
                            @lavrton
                        </a>
                        . I am founder of Polotno project. As the maintainer of{' '}
                        <a href="https://konvajs.org/" target="_blank">
                            Konva 2d canvas framework
                        </a>
                        , I created several similar apps for different companies around the
                        world. So I decided to compile all my knowledge and experience into
                        reusable Polotno project.
                    </p>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={() => toggleFaq(false)}>Close</Button>
                    </div>
                </div>
            </Dialog>
        </>
    );
});
