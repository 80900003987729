import React from 'react';
import { observer } from 'mobx-react-lite';
import { useInfiniteAPI } from 'polotno/utils/use-api';

import { SectionTab } from 'polotno/side-panel';
import MdPhotoLibrary from '@meronex/icons/md/MdFace';

// Import Card component from your library or define it here
import Card from './card';

export const ProjectPanel = observer(({ store }) => {
    // Load data
    const { data, isLoading } = useInfiniteAPI({
        getAPI: ({ page }) => `project/page${page}.json`,
    });

    return (
        <div style={{ height: '100%' }}>
            {data && data.map((pageData, index) => (
                <div key={index}>
                    {pageData.items.map((item, itemIndex) => (
                        <Card
                            key={itemIndex}
                            title={item.title}
                            date={item.date}
                            admin={item.admin}
                            imageUrl={`/project/${item.image}`} // Assuming image URL is in 'image' field
                            onSelect={async () => {
                                const req = await fetch(`/project/${item.json}`);
                                const json = await req.json();
                                store.loadJSON(json);
                            }}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
});

// Define the new custom section
export const ProjectSection = {
    name: 'project-templates',
    Tab: (props) => (
        <SectionTab name="project" {...props}>
            <MdPhotoLibrary />
        </SectionTab>
    ),
    // We need observer to update component automatically on any store changes
    Panel: ProjectPanel,
};
